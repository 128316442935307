@font-face {
    font-family: $fontExtraBoldItlic;
    src: url('../../fonts/metropolis-extra-bold-italic.otf');
  }

  @font-face {
    font-family: $fontSemiBold;
    src: url('../../fonts/metropolis-semi-bold.otf');
  }

  @font-face {
    font-family: $fontBlackItlic;
    src: url('../../fonts/metropolis-black-italic.otf');
  }

  @font-face {
    font-family: $fontBold;
    src: url('../../fonts/metropolis-bold.otf');
  }


  @font-face {
    font-family: $fontMedium;
    src: url('../../fonts/metropolis-medium.otf');
  }
  @font-face {
    font-family: $fontBlack;
    src: url('../../fonts/metropolis-black.otf');
  }

  @font-face {
    font-family: $fontRegular;
    src: url('../../fonts/metropolis-regular.otf');
  }
  @font-face {
    font-family: $fontBoldItalic;
    src: url('../../fonts/metropolis-bold-italic.otf');
  }
