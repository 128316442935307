
body{
  background: #f7fafe;
}
.title{

  @include textStyle(24px,$fontBlackItlic,33px,$BlackRussian);
  text-align: center;
  &:after {
      content: '';
      width: 40px;
      height: 3px;
      margin: 10px auto;
      border-radius: 39px;
      background: $BostonBlue;
      display: block;
  }


}
.mr-btm{
  margin-bottom: 20px;
}
