@mixin textStyle($font-size, $fFamily, $lineHeight,$color:#000) {

    font-size: $font-size;
    font-family: $fFamily;
    line-height: $lineHeight;
    color: $color;
  }

  @mixin desktop {
    @media only screen and (min-width:800px){

      @content;
    }

  }
  @mixin LargeScreen {
    @media only screen and (min-width:1365px){

      @content;
    }

  }
